.rootSvg {
    overflow: visible;
    width: 100%;
    height: 100%;
    // This allows SVG child elements to behave like normal HTML elements
    transform-box: fill-box;

    // This allows the SVG to scale from the centre instead of the top right, making calculations easier
    g {
        transform-origin: center
    }
}

.indicator {
    @apply cursor-pointer;
    @apply transition-colors;
    @apply stroke-[2px];

    &.isHealthy {
        @apply fill-healthy;
        @apply stroke-healthy;
    }
    &.isUnhealthy {
        @apply fill-unhealthy;
        @apply stroke-unhealthy;
    }
    &.isVeryUnhealthy {
        @apply fill-veryUnhealthy;
        @apply stroke-veryUnhealthy;
    }

    // When hovering any indicators, add a stroke to the indicator
    &.isHoveredFromOverviewPanel,
    &:hover {
        @apply stroke-white;
    }

    &.isHoveredFromOverviewPanelButNotActive {
        opacity: 0.6;
    }

    // When an indicator is selected, dull the rest of the indicators
    &.isIndicatorSelected {
        &.isHealthy {
            @apply fill-healthy/60;
            @apply stroke-healthy/80;
        }
        
        &.isUnhealthy {
            @apply fill-unhealthy/60;
            @apply stroke-unhealthy/80;
        }

        &.isVeryUnhealthy {
            @apply fill-veryUnhealthy/60;
            @apply stroke-veryUnhealthy/80;
        }

        &:hover {
            @apply stroke-white;

            &.isHealthy {
                @apply fill-healthy
            }
            
            &.isUnhealthy {
                @apply fill-unhealthy
            }
    
            &.isVeryUnhealthy {
                @apply fill-veryUnhealthy
            }
        }
    
        // When an indicator is selected and it is this one, then add a larger border and fill the indicator
        &.isActive {
            @apply stroke-[4px];
            @apply stroke-white;

            &.isHealthy {
                @apply fill-healthy
            }
            
            &.isUnhealthy {
                @apply fill-unhealthy
            }
    
            &.isVeryUnhealthy {
                @apply fill-veryUnhealthy
            }
        }
    }
}
