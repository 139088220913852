@import './clamp';

$screen-lg-bp: 1440;
$screen-xs-bp: 414;

@mixin huge {
    font-size: #{fluid(3.5rem, 10rem, $screen-xs-bp, 1560)};
    line-height: 1;
    font-weight: 800;
}

@mixin h1 {
    font-size: #{fluid(2.75rem, 5rem, $screen-xs-bp, $screen-lg-bp)};
    line-height: 120%;
    font-weight: 800;
}

@mixin h2 {
    font-size: #{fluid(2.125rem, 3.75rem, $screen-xs-bp, $screen-lg-bp)};
    line-height: 120%;
    font-weight: 800;
}

@mixin h2-alt {
    font-size: #{fluid(1.5rem, 2rem, $screen-xs-bp, $screen-lg-bp)};
    font-weight: 500;
    line-height: 140%;
}

@mixin h3 {
    font-size: #{fluid(1.75rem, 3.125rem, $screen-xs-bp, $screen-lg-bp)};
    line-height: 110%;
    font-weight: 800;
}

@mixin h4 {
    font-size: #{fluid(1.5rem, 2.75rem, $screen-xs-bp, $screen-lg-bp)};
    line-height: 110%;
    font-weight: 800;
}

@mixin stat {
    font-size: #{fluid(3.5rem, 5rem, $screen-xs-bp, $screen-lg-bp)};
    font-weight: 800;
    line-height: 110%;
}

@mixin headline-alt {
    font-size: #{fluid(1.25rem, 1.5rem, $screen-xs-bp, $screen-lg-bp)};
    font-weight: 400;
    line-height: 120%;
}

@mixin headline {
    font-size: #{fluid(1.25rem, 1.5rem, $screen-xs-bp, $screen-lg-bp)};
    line-height: 110%;
    font-weight: 700;
}

@mixin label {
    font-size: #{fluid(1rem, 1.125rem, $screen-xs-bp, $screen-lg-bp)};
    line-height: 120%;
}

@mixin body-1 {
    font-size: #{fluid(1rem, 1.125rem, $screen-xs-bp, $screen-lg-bp)};
    line-height: 150%;
}

@mixin body-2 {
    font-size: #{fluid(.875rem, 1rem , $screen-xs-bp, $screen-lg-bp)};
    line-height: 130%;
}

@mixin body-3 {
    font-size: #{fluid(.75rem, .875rem, $screen-xs-bp, $screen-lg-bp)};
    line-height: 130%;
}

@mixin caption {
    font-size: #{fluid(.625rem, .75rem, $screen-xs-bp, $screen-lg-bp)};
    line-height: 120%;
    font-weight: 600;
}
